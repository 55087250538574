<template>
  <v-expansion-panels v-model="panel" class="mt-4 pb-4" focusable>
    <!-- settings -->
    <v-expansion-panel class="mt-3">
      <v-expansion-panel-header>
        <span><v-icon class="mr-1">sync</v-icon> Ativação</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-alert class="mx-2 mt-4" type="info" dense outlined>Para mais informações sobre ativação de Cashback, checar documentação.</v-alert>
        <v-btn color="primary" text outlined class="ma-2" @click="openOutlineDocs"><v-icon class="mr-2">mdi-label-outline</v-icon> Documentação Cashback</v-btn>
        <v-row no-gutters class="mx-4 mt-4">
          <v-col cols="3">
            <span>Ativar Cashback:</span>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" style="width:min-content;" v-on="on">
                  <v-switch v-model="cashback.active" :disabled="!editable" class="pt-4" value />
                </div>
              </template>
              <span>Ativa o Cashback no canal e nos modulos do cliente</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="2">
            <v-card class="mb-2 input-box" flat>
              <v-card-text>
                <label class="subheading">Data de Início:</label>
                <date-picker v-model="cashback.start_date" :disabled="!editable" :date.sync="cashback.start_date" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card class="mb-2 input-box" flat>
              <v-card-text>
                <label class="subheading">Data Final:</label>
                <date-picker v-model="cashback.end_date" :disabled="!editable" :date.sync="cashback.end_date" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="mb-2 input-box" flat>
              <v-card-title> (Opcional) Condições Personalizadas</v-card-title>
              <v-card-text>
                Aqui você pode editar como o aplicativo exibe as condições do cashback, caso o varejista possua ofertas especiais. Por exemplo: "O cashback
                poderá ser utilizado somente em compras na conveniência" e "O cashback poderá ser utilizado somente em compras acima de R$ 100,00". Essa é uma
                configuração opcional. Caso não seja configurada, o aplicativo utilizará a mensagem padrão.
              </v-card-text>
              <v-row no-gutters class="pa-4 pt-0">
                <v-col>
                  <mf-text-area-input
                    v-model="cashback.observation"
                    :counter="500"
                    :rules="[characterLimit500]"
                    outlined
                    :disabled="!editable"
                    label="CUSTOM_CASHBACK_MESSAGE"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <btn-card-actions class="pb-0" :editable.sync="editable" :saving="saving" @save="saveAppCashback" @cancel="cancelRestoreSettings" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {
  QUERY_GET_ACCOUNT_CONFIGURATION,
  MUTATION_ACTIVATE_APP_CASHBACK,
  QUERY_GET_HOME_BUTTON,
  MUTATION_EDIT_CASHBACK_HOME_BUTTONS
} from '@/modules/accounts/graphql'

export default {
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    DatePicker: () => import('@/components/calendar/DatePicker')
  },
  data: () => ({
    accountId: '',
    editable: false,
    saving: false,
    editableButton: false,
    valid: false,
    panel: 0,
    notEmptyRule: v => !!v || 'Campo obrigatório',
    characterLimit500: v => (v || '').length <= 500 || 'Campo não pode ter mais que 500 caractéres',
    cashback: {
      start_date: '',
      end_date: '',
      active: false,
      observation: ''
    },
    homeButton: {
      flagText: '',
      position: 0,
      title: '',
      units: []
    },
    unit: ''
  }),
  apollo: {
    admiadminGetAccountConfigurationnAccount: {
      query: QUERY_GET_ACCOUNT_CONFIGURATION,
      fetchPolicy: 'network-only',
      variables() {
        return { id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminGetAccountConfiguration }) {
        if (!adminGetAccountConfiguration) return {}
        this.cashback.start_date = adminGetAccountConfiguration?.cashback?.start_date || ''
        this.cashback.end_date = adminGetAccountConfiguration?.cashback?.end_date || ''
        this.cashback.active = adminGetAccountConfiguration?.cashback?.active || false
        this.cashback.observation = adminGetAccountConfiguration?.cashback?.observation || ''
      }
    },
    adminGetHomeButtons: {
      query: QUERY_GET_HOME_BUTTON,
      fetchPolicy: 'network-only',
      variables() {
        return { id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminGetHomeButtons }) {
        if (!adminGetHomeButtons) return {}
        this.homeButton.flagText = adminGetHomeButtons?.flagText
        this.homeButton.position = adminGetHomeButtons?.position || 0
        this.homeButton.title = adminGetHomeButtons?.title
        this.homeButton.units = adminGetHomeButtons?.units || []
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async saveAppCashback() {
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_ACTIVATE_APP_CASHBACK,
          variables: {
            id: this.accountId,
            active: this.cashback.active,
            start_date: this.cashback.start_date,
            end_date: this.cashback.end_date,
            observation: this.cashback.observation
          }
        })
        this.$snackbar({ message: 'Cashback ativado com sucesso', snackbarColor: '#2E7D32' })
        this.editable = false
        setTimeout(() => {
          this.$router.go()
        }, 300)
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao ativar cashback', snackbarColor: '#F44336' })
      }
      this.saving = false
    },
    async saveHomeButton() {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Falha ao validar campos', snackbarColor: '#F44336' })

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_EDIT_CASHBACK_HOME_BUTTONS,
          variables: {
            id: this.accountId,
            flagText: this.homeButton.flagText,
            position: +this.homeButton.position,
            title: this.homeButton.title,
            units: this.homeButton.units
          }
        })
        this.cancelRestoreHomeButtons()
        this.$snackbar({ message: 'Botão Cashback salvo com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao salvar cashback home_button', snackbarColor: '#F44336' })
      }
    },
    splitUnit(value) {
      const unitIndex = this.homeButton.units.indexOf(value)
      this.homeButton.units.splice(unitIndex, 1)
    },
    pushUnit(value) {
      this.unit = ''
      if (!this.homeButton.units.includes(value)) return this.homeButton.units.push(value)
      else return this.$snackbar({ message: 'Loja já inserida', snackbarColor: '#F44336' })
    },
    cancelRestoreSettings() {
      this.editable = false
      this.$apollo.queries.admiadminGetAccountConfigurationnAccount.refetch()
    },
    cancelRestoreHomeButtons() {
      this.editableButton = false
      this.$apollo.queries.adminGetHomeButtons.refetch()
    },
    openOutlineDocs() {
      return window.open('https://mercafacil.getoutline.com/doc/ativacao-cashback-mHKk4PP4jT', '_blank')
    }
  }
}
</script>
